import React from "react"
import { Link } from "gatsby"
import { Alert } from "react-bootstrap"

import Seo from "../components/seo"

const CanceledPage = () => (
  <>
    <Seo title="Canceled" />
    <Alert variant='warning'>
      The transaction was canceled.
    </Alert>
    <Link to="/account">Go back to the Account Page</Link>
  </>
)

export default CanceledPage